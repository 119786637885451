import {Application} from "stimulus";
import React from 'react';
import {hydrate, render} from "react-dom";
import {
    ModalWindowController,
    SelectController,
    SiteSubsubmenuController,
    StickyBarController,
    TabsController,
    WaveController
} from '@make/ds2';
import {
    AccordionController,
    ArticleSliderController,
    BackLinkController,
    CopyLinkController,
    FooterMenuController,
    PageAsideMenuController,
    PhotoSliderController,
    SiteMenuController,
    SiteMenuModalController,
    SiteSearchController,
    ViewMoreController
} from './components/controllers';
import $ from "jquery";
import {validateFormInit} from "./jquery-libs/validation";
import {newsCounter} from "./jquery-libs/newsCounter";
import {getGeneralBanners} from "./jquery-libs/generalBanners";
import {messagePopupInit} from "./jquery-libs/messagePopup";
import DisclaimerPopup from './jquery-libs/disclaimer-popup';
import bvi from "../components/vendor/bvi/dist/js/bvi";
import {enableBodyScroll} from "body-scroll-lock";
import ReactModal from "react-modal";
import App from "../components/App";
import * as serviceWorker from "../../serviceWorker";
import {FormInTextPublic} from "../components/blocks/constructor_components/form_components/form_in_text_public";

const application = Application.start();
application.register('wave', WaveController);
application.register('sticky-bar', StickyBarController);
application.register('accordion', AccordionController);
application.register('site-menu', SiteMenuController);
application.register('site-subsubmenu', SiteSubsubmenuController);
application.register('site-menu-modal', SiteMenuModalController);
application.register('modal-window', ModalWindowController);
application.register('view-more', ViewMoreController);
application.register('article-slider', ArticleSliderController);
application.register('copy-link', CopyLinkController);
application.register('tabs', TabsController);
application.register('aside-menu', PageAsideMenuController);
application.register('back-link', BackLinkController);
application.register('footer-menu', FooterMenuController);
application.register('photo-slider', PhotoSliderController);
application.register('site-search', SiteSearchController);
application.register('select', SelectController);

window.reloadAsideMenu = function () {
    application.register('aside-menu', PageAsideMenuController);
};

window.reloadGallerySlider = function () {
    application.register('article-slider', ArticleSliderController);
};

function initBvi() {
    new bvi.Bvi({
        target: '.site-menu__visually-impaired',
        fontSize: 24,
        theme: 'black',
        speech: false
    });

    enableBodyScroll(window.$('body'));
}

const formElement = document.getElementById('constructor-form-in-text--render-block');

if (formElement && navigator.userAgent !== "ReactSnap") {
    hydrate(<FormInTextPublic/>, formElement);
}

$(document).ready(function () {
    if (process.env.REACT_APP_BUILD_MODE !== 'production'){
        $("#header-base").load("/header-base/index.html #page-header");
        $("#footer-base").load("/footer-base/index.html #page-footer");
        setTimeout(function () {
            $(".App").css('opacity', 1);
        }, 500);
    }

    setTimeout(function () {
        messagePopupInit();
        getGeneralBanners();
        initBvi();
        newsCounter();
        //validateFormInit();
        const disclaimer = new DisclaimerPopup();
        if (!window.localStorage.getItem('gosweb-disclaimer-shown')) {
            disclaimer.showOnce();
        }
    }, 2000);
});
