import {Controller} from 'stimulus';

export default class PageAsideMenuController extends Controller {
    static targets = ['indicator', 'link', 'spoiler', 'spoilerContent'];

    firstLinkTop = 0;

    parentSpoilerTopPosition;

    parentSpoiler;

    connect() {
        setTimeout(function () {
            if (this.linkTargets[0]) {
                this.firstLinkTop = this.linkTargets[0].getBoundingClientRect().top;
                this.slideIndicator();
                window.addEventListener('resize', this.slideIndicator);
                this.spoilerContentTargets.forEach((item) => {
                    item.addEventListener('transitionend', this.slideIndicator);
                });
                if (this.parentSpoiler) {
                    const timeout = setTimeout(() => {
                        this.parentSpoiler.dispatchEvent(new Event('openMenu'));
                        clearTimeout(timeout)
                    }, 0);
                }
            }
        }.bind(this), 300)

    }

    disconnect() {
        const activeLink = this.getActiveLink();
        if (!activeLink) {
            if (this.hasIndicatorTarget) {
                this.indicatorTarget.style.height = `0px`;
                this.indicatorTarget.style.transform = `translateY(0px)`
            }
        }

        window.removeEventListener('resize', this.slideIndicator);
        window.removeEventListener('load', this.loadHandler);
    }

    getActiveLink = () => {
        return this.linkTargets.find((link) => link.classList.contains('active'));
    };

    getParentSpoiler = (targetElement) => {
        let element = targetElement;
        while (element) {
            if (element.classList.contains('anchor-menu__spoiler-content')) {
                this.parentSpoiler = element.parentElement;
                this.parentSpoilerTopPosition = this.parentSpoiler ? window.$(this.parentSpoiler).offset().top : 0;
                return true;
            }

            element = element.parentElement;
        }

        return false;
    };

    getButton = (activeLink) => {
        const parentElement = activeLink.parentElement;

        return parentElement.querySelector('.anchor-menu-spoiler__button');
    };

    slideIndicator = () => {
        let delta = 4;
        let currentTopPosition;

        const activeLink = this.getActiveLink();
        if (activeLink) {
            this.getParentSpoiler(activeLink);

            const {height} = activeLink.getBoundingClientRect();
            const {top} = window.$(activeLink).offset();
            const wrapperTop = window.$('.page-aside-menu__wrapper').offset().top;
            const currentScrollTop = window.$(window).scrollTop();

            currentTopPosition = top - this.firstLinkTop;

            if (currentScrollTop > wrapperTop) {
                let newPosition = currentTopPosition - (currentScrollTop - wrapperTop) - 40;
                // if(newPosition > 0){
                //     newPosition = currentTopPosition - (currentScrollTop + wrapperTop - currentScrollTop - wrapperTop) + 40;
                // }

                currentTopPosition = newPosition
            }

            if (this.parentSpoiler) {
                if (!this.parentSpoiler.classList.contains('opened')) {
                    currentTopPosition -= top - this.parentSpoilerTopPosition;
                }
            }
            if (this.hasIndicatorTarget) {
                this.indicatorTarget.style.height = `${height}px`;
                this.indicatorTarget.style.transform = `translateY(${currentTopPosition + delta}px)`
            }

        } else {
            if (this.hasIndicatorTarget) {
                this.indicatorTarget.style.height = `0px`;
                this.indicatorTarget.style.transform = `translateY(0px)`
            }
        }

    }
}
