import React, {useState} from 'react';
import Hint from '../Hint/Hint';
import '../../../../styles/public_site/components/public-input-field.scss';

const InputField = ({label, error, focused, children, hint, inputClassName = '', description = ''}) => {
    const [showHint, setShowHint] = useState(false);

    return (
        <div className={`constructor-input-field__wrapper ${error ? 'error' : ''}`}>
            <div className='constructor-input-field__label-wrapper'>
                <label className='constructor-input-field__label'>
                    {label}

                    {hint && (
                        <span
                            onMouseEnter={() => setShowHint(true)}
                            onMouseLeave={() => setShowHint(false)}
                            className='constructor-input-field__hint-dot'
                        />
                    )}

                </label>
                <div className={'constructor-input-field__label-description'}>
                    {description}
                </div>
                <span className='constructor-input-field__error-message'>
                    {error && error.message}
                </span>
            </div>
            <div className={`constructor-input-field__input ${focused ? 'focused' : ''} ${inputClassName}`}>
                {children}
            </div>
            {hint && <Hint title={hint} show={showHint}/>}
        </div>
    );
};

export default InputField;
