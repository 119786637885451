import * as types from '../constants/ActionTypes';
import {BASE_URL} from "../constants/EnvVariables";
import Cookies from 'js-cookie';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getSettingsMainPage() {
    return dispatch => {
        dispatch({
            type: types.GET_SETTINGS_MAIN_PAGE_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/settings_main_page",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_SETTINGS_MAIN_PAGE_SUCCESS,
                    info: data,
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_SETTINGS_MAIN_PAGE_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });

    };
}

export function setSettingsMainPage(payload) {

    return {
        type: types.SET_SETTINGS_MAIN_PAGE,
        payload,
    };
}

export function clearSettingsMainPage() {
    return dispatch => {
        dispatch({
            type: types.GET_SETTINGS_MAIN_PAGE_SUCCESS,
            info: [],
        });
    };
}