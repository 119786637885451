import * as types from '../constants/ActionTypes';

const initialState = {news: []};

export function news(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_NEWS_STARTED:
            return state;

        case types.GET_NEWS_SUCCESS:
            return action.info;

        case types.GET_NEWS_ERROR:
            return state;

        case types.ADD_NEWS_STARTED:
            return state;

        case types.ADD_NEWS_SUCCESS:
            return {
                ...state,
                news: [action.info, ...state.news]
            };

        case types.ADD_NEWS_ERROR:
            return state;


        case types.EDIT_NEWS_STARTED:
            return state;

        case types.EDIT_NEWS_SUCCESS:
            return {
                ...state,
                news: state.news.map(
                    (news) => news.id === parseInt(action.newsId) ? action.info
                        : news
                )
            };

        case types.EDIT_NEWS_ERROR:
            return state;

        case types.DELETE_NEWS_STARTED:
            return state;

        case types.DELETE_NEWS_SUCCESS:
            return {
                ...state,
                news: state.news.filter((news, i) => news.id !== parseInt(action.newsId))
            };

        case types.DELETE_NEWS_ERROR:
            return state;


        default:
            return state;
    }
}
