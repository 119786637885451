import {useEffect} from 'react';

export const useWave = (ref) => {
    useEffect(() => {
        const waveFadeInClassName = 'wave--fade-in';
        const waveFadeOutClassName = 'wave--fade-out';
        const hoverWaveClassName = 'wave--hover';
        const activeWaveClassName = 'wave--active';
        const removeWavePropertyName = 'opacity';

        const waves = [];

        const element = ref.current;
        const wrapper = document.createElement('div');
        wrapper.classList.add('wave-wrapper');
        element.appendChild(wrapper);

        const onMouseEnter = (event) => {
            createWave(event.pageX, event.pageY, hoverWaveClassName);
        };

        const onMouseLeave = () => {
            removeWaves(hoverWaveClassName);
        };

        const onMouseDown = (event) => {
            createWave(event.pageX, event.pageY, activeWaveClassName);
        };

        const onMouseUp = () => {
            removeWaves(activeWaveClassName);
        };

        const onWaveTransitionEnd = (event) => {
            if (event.propertyName !== removeWavePropertyName) {
                return;
            }

            const index = waves.indexOf(event.target);
            if (index >= 0) {
                waves.splice(index, 1);
            }

            event.target.removeEventListener('transitionend', onWaveTransitionEnd);

            wrapper.removeChild(event.target);
        };
        const removeWaves = (className) => {
            waves.forEach((wave) => {
                if (wave.classList.contains(className)) {
                    wave.classList.add(waveFadeOutClassName);
                }
            });
        };

        const createWave = (xp, yp, className) => {
            const rect = wrapper.getBoundingClientRect();
            const x = xp - rect.x - window.pageXOffset;
            const y = yp - rect.y - window.pageYOffset;
            const w = rect.width;
            const h = rect.height;

            const r12 = x * x + y * y;
            const r22 = x * x + (y - h) * (y - h);
            const r32 = (x - w) * (x - w) + (y - h) * (y - h);
            const r42 = (x - w) * (x - w) + y * y;
            const r = Math.sqrt(Math.max(r12, r22, r32, r42));

            const wave = document.createElement('div');
            wave.classList.add('wave', className);
            wave.addEventListener('transitionend', onWaveTransitionEnd);
            waves.push(wave);
            Object.assign(wave.style, {
                width: `${2 * r}px`,
                height: `${2 * r}px`,
                left: `${x - r}px`,
                top: `${y - r}px`,
            });
            wrapper.appendChild(wave);

            /* eslint-disable no-unused-expressions */
            wave.offsetWidth;
            wave.classList.add(waveFadeInClassName);
        };

        element.addEventListener('mouseenter', onMouseEnter);
        element.addEventListener('mouseleave', onMouseLeave);
        element.addEventListener('mousedown', onMouseDown);
        document.addEventListener('mouseup', onMouseUp);

        return () => {
            element.removeEventListener('mouseenter', onMouseEnter);
            element.removeEventListener('mouseleave', onMouseLeave);
            element.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, []);
};
