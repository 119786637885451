import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';

export function messagePopupInit() {

    $.each($('.js-message-popup-wrapper'), function (index, item) {
        // $(item).addClass('init');

        $(item).on('mouseenter', function () {
            $(this).find('.js-message-popup').addClass('open');
        })

        $(item).on('mouseleave', function () {
            $(this).find('.js-message-popup').removeClass('open');
        })

    });
}
