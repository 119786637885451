import * as types from '../constants/ActionTypes';

const initialState = {pages: []};


export function pages(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_PAGES_STARTED:
            return state;

        case types.GET_PAGES_SUCCESS:
            return action.info;

        case types.GET_PAGES_ERROR:
            return state;

        case types.ADD_PAGE_STARTED:
            return state;

        case types.ADD_PAGE_SUCCESS:
            return {
                ...state,
                pages: [...state.pages, action.info]
            };

        case types.ADD_PAGE_ERROR:
            return state;

        case types.EDIT_PAGE_STARTED:
            return state;

        case types.EDIT_PAGE_SUCCESS:
            return {
                ...state,
                pages: state.pages.map(
                    (page) => page.url === action.info.url ? {
                            ...page,
                            jsonContent: action.info.jsonContent,
                            seo_title: action.info.title,
                            seo_description: action.info.description,
                            seo_keywords: action.info.keywords
                        }
                        : page
                )
            };

        case types.EDIT_PAGE_ERROR:
            return state;

        default:
            return state;
    }
}

export default pages