// Типы экшинов для редьюсеров

export const GET_STRUCTURE_STARTED = 'GET_STRUCTURE_STARTED';
export const GET_STRUCTURE_SUCCESS = 'GET_STRUCTURE_SUCCESS';
export const GET_STRUCTURE_ERROR = 'GET_STRUCTURE_ERROR';

export const ADD_STRUCTURE_PAGE_STARTED = 'ADD_STRUCTURE_PAGE_STARTED';
export const ADD_STRUCTURE_PAGE_SUCCESS = 'ADD_STRUCTURE_PAGE_SUCCESS';
export const ADD_STRUCTURE_PAGE_ERROR = 'ADD_STRUCTURE_PAGE_ERROR';


export const EDIT_STRUCTURE_PAGE_STARTED = 'EDIT_STRUCTURE_PAGE_STARTED';
export const EDIT_STRUCTURE_PAGE_SUCCESS = 'EDIT_STRUCTURE_PAGE_SUCCESS';
export const EDIT_STRUCTURE_PAGE_ERROR = 'EDIT_STRUCTURE_PAGE_ERROR';

export const DELETE_STRUCTURE_PAGE_STARTED = 'DELETE_STRUCTURE_PAGE_STARTED';
export const DELETE_STRUCTURE_PAGE_SUCCESS = 'DELETE_STRUCTURE_PAGE_SUCCESS';
export const DELETE_STRUCTURE_PAGE_ERROR = 'DELETE_STRUCTURE_PAGE_ERROR';

export const UPDATE_PRIORITY_STRUCTURE_PAGE_STARTED = 'UPDATE_PRIORITY_STRUCTURE_PAGE_STARTED';
export const UPDATE_PRIORITY_STRUCTURE_PAGE_SUCCESS = 'UPDATE_PRIORITY_STRUCTURE_PAGE_SUCCESS';
export const UPDATE_PRIORITY_STRUCTURE_PAGE_ERROR = 'UPDATE_PRIORITY_STRUCTURE_PAGE_ERROR';

export const GET_PAGES_STARTED = 'GET_PAGES_STARTED';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_ERROR = 'GET_PAGES_ERROR';

export const ADD_PAGE_STARTED = 'ADD_PAGE_STARTED';
export const ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';
export const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';

export const EDIT_PAGE_STARTED = 'EDIT_PAGE_STARTED';
export const EDIT_PAGE_SUCCESS = 'EDIT_PAGE_SUCCESS';
export const EDIT_PAGE_ERROR = 'EDIT_PAGE_ERROR';

export const GET_SETTINGS_STARTED = 'GET_SETTINGS_STARTED';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';

export const SET_SETTINGS_STARTED = 'SET_SETTINGS_STARTED';
export const SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS';
export const SET_SETTINGS_ERROR = 'SET_SETTINGS_ERROR';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';

export const GET_NEWS_STARTED = 'GET_NEWS_STARTED';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';

export const ADD_NEWS_STARTED = 'ADD_NEWS_STARTED';
export const ADD_NEWS_SUCCESS = 'ADD_NEWS_SUCCESS';
export const ADD_NEWS_ERROR = 'ADD_NEWS_ERROR';

export const EDIT_NEWS_STARTED = 'EDIT_NEWS_STARTED';
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export const EDIT_NEWS_ERROR = 'EDIT_NEWS_ERROR';

export const DELETE_NEWS_STARTED = 'DELETE_NEWS_STARTED';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_ERROR = 'DELETE_NEWS_ERROR';

export const GET_ORGANIZATION_CATEGORIES_STARTED = 'GET_ORGANIZATION_CATEGORIES_STARTED';
export const GET_ORGANIZATION_CATEGORIES_SUCCESS = 'GET_ORGANIZATION_CATEGORIES_SUCCESS';
export const GET_ORGANIZATION_CATEGORIES_ERROR = 'GET_ORGANIZATION_CATEGORIES_ERROR';

export const GET_COLOR_SCHEMES_STARTED = 'GET_COLOR_SCHEMES_STARTED';
export const GET_COLOR_SCHEMES_SUCCESS = 'GET_COLOR_SCHEMES_SUCCESS';
export const GET_COLOR_SCHEMES_ERROR = 'GET_COLOR_SCHEMES_ERROR';

export const GET_SITE_SAMPLES_STARTED = 'GET_SITE_SAMPLES_STARTED';
export const GET_SITE_SAMPLES_SUCCESS = 'GET_SITE_SAMPLES_SUCCESS';
export const GET_SITE_SAMPLES_ERROR = 'GET_SITE_SAMPLES_ERROR';

export const GET_SETTINGS_MAIN_PAGE_STARTED = 'GET_SETTINGS_MAIN_PAGE_STARTED';
export const GET_SETTINGS_MAIN_PAGE_SUCCESS = 'GET_SETTINGS_MAIN_PAGE_SUCCESS';
export const GET_SETTINGS_MAIN_PAGE_ERROR = 'GET_SETTINGS_MAIN_PAGE_ERROR';
export const SET_SETTINGS_MAIN_PAGE = 'SET_SETTINGS_MAIN_PAGE';

export const GET_PERSON_STARTED = 'GET_PERSON_STARTED';
export const GET_PERSON_SUCCESS = 'GET_PERSON_SUCCESS';
export const GET_PERSON_ERROR = 'GET_PERSON_ERROR';

export const ADD_PERSON_STARTED = 'ADD_PERSON_STARTED';
export const ADD_PERSON_SUCCESS = 'ADD_PERSON_SUCCESS';
export const ADD_PERSON_ERROR = 'ADD_PERSON_ERROR';

export const EDIT_PERSON_STARTED = 'EDIT_PERSON_STARTED';
export const EDIT_PERSON_SUCCESS = 'EDIT_PERSON_SUCCESS';
export const EDIT_PERSON_ERROR = 'EDIT_PERSON_ERROR';

export const DELETE_PERSON_STARTED = 'DELETE_PERSON_STARTED';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_ERROR = 'DELETE_PERSON_ERROR';

export const SET_PERSONALITIES_SECTION = 'SET_PERSONALITIES_SECTION';
export const DELETE_PERSONALITIES_PARENT_SECTION = 'DELETE_PERSONALITIES_PARENT_SECTION';

export const GET_SUBDIVISION_STARTED = 'GET_SUBDIVISION_STARTED';
export const GET_SUBDIVISION_SUCCESS = 'GET_SUBDIVISION_SUCCESS';
export const GET_SUBDIVISION_ERROR = 'GET_SUBDIVISION_ERROR';

export const ADD_SUBDIVISION_STARTED = 'ADD_SUBDIVISION_STARTED';
export const ADD_SUBDIVISION_SUCCESS = 'ADD_SUBDIVISION_SUCCESS';
export const ADD_SUBDIVISION_ERROR = 'ADD_SUBDIVISION_ERROR';

export const EDIT_SUBDIVISION_STARTED = 'EDIT_SUBDIVISION_STARTED';
export const EDIT_SUBDIVISION_SUCCESS = 'EDIT_SUBDIVISION_SUCCESS';
export const EDIT_SUBDIVISION_ERROR = 'EDIT_SUBDIVISION_ERROR';

export const DELETE_SUBDIVISION_STARTED = 'DELETE_SUBDIVISION_STARTED';
export const DELETE_SUBDIVISION_SUCCESS = 'DELETE_SUBDIVISION_SUCCESS';
export const DELETE_SUBDIVISION_ERROR = 'DELETE_SUBDIVISION_ERROR';

export const DELETE_PARENT_SUBDIVISION_GROUP = 'DELETE_PARENT_SUBDIVISION_GROUP';
export const DELETE_SUBDIVISION_PERSON = 'DELETE_SUBDIVISION_PERSON';
export const SORT_SUBDIVISION = 'SORT_SUBDIVISION';
export const SORT_SUBDIVISION_WITHOUT_GROUP = 'SORT_SUBDIVISION_WITHOUT_GROUP';

export const GET_SUBDIVISION_GROUP_STARTED = 'GET_SUBDIVISION_GROUP_STARTED';
export const GET_SUBDIVISION_GROUP_SUCCESS = 'GET_SUBDIVISION_GROUP_SUCCESS';
export const GET_SUBDIVISION_GROUP_ERROR = 'GET_SUBDIVISION_GROUP_ERROR';

export const ADD_SUBDIVISION_GROUP_STARTED = 'ADD_SUBDIVISION_GROUP_STARTED';
export const ADD_SUBDIVISION_GROUP_SUCCESS = 'ADD_SUBDIVISION_GROUP_SUCCESS';
export const ADD_SUBDIVISION_GROUP_ERROR = 'ADD_SUBDIVISION_GROUP_ERROR';

export const EDIT_SUBDIVISION_GROUP_STARTED = 'EDIT_SUBDIVISION_GROUP_STARTED';
export const EDIT_SUBDIVISION_GROUP_SUCCESS = 'EDIT_SUBDIVISION_GROUP_SUCCESS';
export const EDIT_SUBDIVISION_GROUP_ERROR = 'EDIT_SUBDIVISION_GROUP_ERROR';
export const SORT_SUBDIVISION_GROUPS = 'SORT_SUBDIVISION_GROUPS';

export const DELETE_SUBDIVISION_GROUP_STARTED = 'DELETE_SUBDIVISION_GROUP_STARTED';
export const DELETE_SUBDIVISION_GROUP_SUCCESS = 'DELETE_SUBDIVISION_GROUP_SUCCESS';
export const DELETE_SUBDIVISION_GROUP_ERROR = 'DELETE_SUBDIVISION_GROUP_ERROR';

export const GET_MANAGEMENT_STARTED = 'GET_MANAGEMENT_STARTED';
export const GET_MANAGEMENT_SUCCESS = 'GET_MANAGEMENT_SUCCESS';
export const GET_MANAGEMENT_ERROR = 'GET_MANAGEMENT_ERROR';

export const SET_MANAGEMENT_STARTED = 'SET_MANAGEMENT_STARTED';
export const SET_MANAGEMENT_SUCCESS = 'SET_MANAGEMENT_SUCCESS';
export const SET_MANAGEMENT_ERROR = 'SET_MANAGEMENT_ERROR';

export const DELETE_MANAGER = 'DELETE_MANAGER';


export const GET_GENERAL_BANNER_STARTED = 'GET_GENERAL_BANNER_STARTED';
export const GET_GENERAL_BANNER_SUCCESS = 'GET_GENERAL_BANNER_SUCCESS';
export const GET_GENERAL_BANNER_ERROR = 'GET_GENERAL_BANNER_ERROR';
export const GET_FOOD_STARTED = 'GET_FOOD_STARTED';
export const GET_FOOD_SUCCESS = 'GET_FOOD_SUCCESS';
export const GET_FOOD_ERROR = 'GET_FOOD_ERROR';


export const GET_FEEDBACKS_STARTED = 'GET_FEEDBACKS_STARTED';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_ERROR = 'GET_FEEDBACKS_ERROR';
