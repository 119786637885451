import React, {forwardRef} from "react";
import InputField from '../InputField/InputField';

const TextInput = ({value, error, name, label, hint, description, ...otherProps}, ref) => {
    return (
        <InputField label={label} hint={hint} error={error} description={description}>
            <input ref={ref} value={value} name={name} {...otherProps} />
        </InputField>
    );
};

export default forwardRef(TextInput);
