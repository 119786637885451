import * as types from '../constants/ActionTypes';
import {BASE_URL} from "../constants/EnvVariables";
import Cookies from "js-cookie";
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getSubdivisions() {
    return dispatch => {
        dispatch({
            type: types.GET_SUBDIVISION_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/subdivisions",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_SUBDIVISION_SUCCESS,
                    payload: {subdivisions: data},
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_SUBDIVISION_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    };

}

export function addSubdivision(subdivision) {

    return dispatch => {
        dispatch({
            type: types.ADD_SUBDIVISION_STARTED
        });

        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: BASE_URL + "api/subdivisions",
                data: snakeKeys(subdivision),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    subdivision.id = data.id;
                    dispatch({
                        type: types.ADD_SUBDIVISION_SUCCESS,
                        payload: subdivision,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.ADD_SUBDIVISION_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    }
}

export function deleteSubdivision(id, subdivisionId) {
    return dispatch => {
        dispatch({
            type: types.DELETE_SUBDIVISION_STARTED
        });


        if (!window.editBlocked) {
            window.$.ajax({
                type: "DELETE",
                dataType: 'json',
                url: BASE_URL + "api/subdivisions/" + id,
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    dispatch({
                        type: types.DELETE_SUBDIVISION_SUCCESS,
                        payload: id,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.DELETE_SUBDIVISION_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }

}

export function deleteParentSubdivisionGroup(id) {
    return {
        type: types.DELETE_PARENT_SUBDIVISION_GROUP,
        payload: id,
    }
}

export function editSubdivision(subdivision) {
    return dispatch => {
        dispatch({
            type: types.EDIT_SUBDIVISION_STARTED
        });


        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "PUT",
                dataType: 'json',
                url: BASE_URL + "api/subdivisions/" + subdivision.id,
                data: snakeKeys(subdivision),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    dispatch({
                        type: types.EDIT_SUBDIVISION_SUCCESS,
                        payload: subdivision,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.EDIT_SUBDIVISION_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    }
}

export function deleteSubdivisionPerson(id) {
    return {
        type: types.DELETE_SUBDIVISION_PERSON,
        payload: id,
    }
}

export function sortSubdivision(oldIndex, newIndex, groupId) {
    return {
        type: types.SORT_SUBDIVISION,
        payload: {oldIndex, newIndex, groupId},
    }
}

export function sortSubdivisionWithoutGroup(ids) {
    return {
        type: types.SORT_SUBDIVISION_WITHOUT_GROUP,
        payload: ids,
    }
}

export function clearSubdivisions() {
    return dispatch => {
        dispatch({
            type: types.GET_SUBDIVISION_SUCCESS,
            payload: {subdivisions: []},
        });
    };
}
