import {Controller} from 'stimulus';
import $ from "jquery";
import {BASE_URL, PUBLIC_URL} from "../../../constants/EnvVariables";
import Cookies from "js-cookie";

export default class SiteSearchController extends Controller {
    static targets = [
        'input',
        'suggestion',
        'clear',
        'submit',
        'resultsContainer',
        'result',
    ];

    xhr = null;

    ajaxEndpoint = null;

    searchEndpoint = null;

    focused = false;

    all_content = '';

    url = new URL(window.location.href);

    connect() {
        this.inputTarget.addEventListener('input', this.onInput);
        this.inputTarget.addEventListener('blur', this.onBlur);
        this.inputTarget.addEventListener('focus', this.onFocus);
        this.clearTarget.addEventListener('click', this.clear);
        window.addEventListener('keyup', this.onKeyUp);
        this.inputTarget.value = this.inputTarget.dataset.value ? this.inputTarget.dataset.value : '';
        if (this.inputTarget.value.length) {
            this.inputTarget.classList.add('not-empty');
        }
        this.ajaxEndpoint = this.element.dataset.ajaxEndpoint;
        // this.searchEndpoint = this.element.dataset.searchEndpoint;
        this.searchEndpoint = '/search-result?query=';

        let searchParams = new URLSearchParams(window.location.search);
        let searchStartString = searchParams.get('query');
        if (searchStartString) {
            $(this.inputTarget).val(searchStartString);
            this.inputTarget.classList.add('not-empty');
            window.requestAnimationFrame(() => this.performRequest(searchStartString));
        }

        if (this.hasSuggestionTarget) {
            this.suggestionTarget.addEventListener('click', this.onSuggestion);
        }

        if (this.searchEndpoint) {
            this.submitTarget.addEventListener('click', this.onSearch);
        }
    }

    disconnect() {
        this.inputTarget.removeEventListener('input', this.onInput);
        this.inputTarget.removeEventListener('blur', this.onBlur);
        this.inputTarget.removeEventListener('focus', this.onFocus);
        this.clearTarget.removeEventListener('click', this.clear);
        window.removeEventListener('keyup', this.onKeyUp);
        if (this.hasSuggestionTarget) {
            this.suggestionTarget.removeEventListener('click', this.onSuggestion);
        }
        if (this.searchEndpoint) {
            this.submitTarget.removeEventListener('click', this.onSearch);
        }
    }

    clear = () => {
        this.inputTarget.value = '';
        this.inputTarget.focus();
        this.inputTarget.classList.remove('not-empty');
        this.closeResultsContainer();
    };

    performRequest(query) {
        let self = this;
        let authHeader = Cookies.get('site_builder_token');
        $.ajax({
            type: "GET",
            dataType: "json",
            url: BASE_URL + 'api/search',
            headers: {
                'Authorization': authHeader ? "Bearer " + Cookies.get('site_builder_token') : null
            },
            data: {search_string: query, url: $(window.location).attr('hostname')},
            success: function (data, status, xhr) {
                self.all_content = '';
                self.renderNews(data.news);
                self.renderPages(data.pages, data.highlightedPages);
                self.renderPersons(data.persons);
                self.renderSubdivisionGroups(data.subdivisionGroups);
                self.renderSubdivisions(data.subdivisions);
                setTimeout(function () {
                    $('#tabs__content--all .search-results__list').html(self.all_content);
                }, 500);
            },
            error: function (data) {
            }
        })
    }

    getQueryParameters() {
        return this.element.dataset.queryParameters || '';
    }

    performSearch(query) {
        window.location.href = `${this.searchEndpoint}${query}${this.getQueryParameters()}`;
    }

    isElementInside = (targetElement) => {
        let element = targetElement;
        while (element) {
            if (element === this.element) {
                return true;
            }

            element = element.parentElement;
        }

        return false;
    };

    onInput = (event) => {
        if (event.target.value.length) {
            this.inputTarget.classList.add('not-empty');
            if (this.ajaxEndpoint && event.target.value.length > 2) {
                window.requestAnimationFrame(() => this.performRequest(event.target.value));
            } else {
                this.closeResultsContainer();
            }
        } else {
            this.closeResultsContainer();
            this.inputTarget.classList.remove('not-empty');
        }
    };

    onFocus = () => {
        if (this.inputTarget.value.length > 2 && this.resultsContainerTarget.children.length) {
            this.openResultsContainer();
        }

        this.focused = true;
    };

    openResultsContainer() {
        this.element.classList.add('loaded');
    }

    closeResultsContainer() {
        this.element.classList.remove('loaded');
    }

    onBlur = (event) => {
        if (!this.isElementInside(event.relatedTarget)) {
            this.closeResultsContainer();
            this.focused = false;
        }
    };

    onSuggestion = (event) => {
        this.performSearch(event.target.textContent.trim());
    };

    onSearch = () => {
        this.performSearch(this.inputTarget.value);
    };

    onKeyUp = (event) => {
        if (!this.focused) {
            return;
        }

        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.performSearch(this.inputTarget.value);
        }
    };


    renderNews = (data) => {
        let content = '';
        if (data) {
            data.forEach(function (item, index) {
                content += '<article class="news news--row"><div class="news__publication-info"><a class="news__topic-link" href="/news/' + item.slug + '">Новости</a><time class="news__publication-date" datetime="' + item.publicationDate + '">' + item.publicationDate + '</time></div><a class="news__link" href="/news/' + item.slug + '"><h3 class="news__title">' + item.title + '</h3><p class="news__description">' + item.announce + '</p></a><div class="news__illustration">' + (item.image ? '<img src="' + PUBLIC_URL + (item.image ? item.image.file : '') + '" alt="' + (item.image ? item.image.name : '') + '" class="news__illustration-image">' : '') + '</div></article>'
            });

            $('#tabs__content--news .search-results__list').html(content);
            this.all_content += content
        }
    };

    renderPages = (data, highlighted) => {
        let content = '';
        if (data) {
            data.forEach(function (item, index) {
                content += '<article class="news news--row"><div class="news__publication-info"><a class="news__topic-link" href="' + item.url + '">Страница</a><time class="news__publication-date" datetime="' + '' + '">' + '' + '</time></div><a class="news__link" href="' + item.url + '"><h3 class="news__title">' + item.name + '</h3><p class="news__description">' + (highlighted && highlighted[index] ? highlighted[index] : ' ') + '</p></a></article>'
            });
            $('#tabs__content--pages .search-results__list').html(content);
            this.all_content += content
        }

    };

    renderPersons = (data) => {
        let content = '';
        if (data) {
            data.forEach(function (item, index) {
                content += '<div class="press-service__contact press-service__contact--main"><div class="news__publication-info"><a class="news__topic-link" href="/person/' + item.id + '">Персона</a></div><div class="contact__info"><div class="news__publication-info"><a class="news__topic-link" href="/person/' + item.id + '">Персона</a></div><a href="/person/' + item.id + '" class="contacts-person__link"><div class="contact__name"><div class="contact__last-name">' + item.lastName + '</div>' + item.firstName + ' ' + item.patronymic + '</div><div class="contact__title">' + item.position + '</div></a></div><div class="contact__photo">' + (item.image ? '<img src="' + PUBLIC_URL + (item.image ? item.image.file : '') + '" alt="' + (item.image ? item.image.name : '') + '">' : '') + '</div></div>'
            });
            $('#tabs__content--persons .search-results__list').html(content);
            this.all_content += content
        }
    };

    renderSubdivisionGroups = (data) => {
        let content = '';
        if (data) {
            data.forEach(function (item, index) {
                content += '<article class="news news--row"><div class="news__publication-info"><a class="news__topic-link" href="/public-subdivisions/' + item.slug + '">Группа подразделений</a><time class="news__publication-date" datetime="' + '' + '">' + '' + '</time></div><a class="news__link" href="/public-subdivisions/' + item.slug + '"><h3 class="news__title">' + item.name + '</h3><p class="news__description">' + '' + '</p></a></article>'
            });
            $('#tabs__content--subdivision-groups .search-results__list').html(content);
            this.all_content += content
        }
    };

    renderSubdivisions = (data) => {
        let content = '';
        if (data) {
            data.forEach(function (item, index) {
                content += '<article class="news news--row"><div class="news__publication-info"><a class="news__topic-link" href="/public-subdivisions/' + item.slug + '">Группа подразделений</a><time class="news__publication-date" datetime="' + '' + '">' + '' + '</time></div><a class="news__link" href="/public-subdivision/' + item.slug + '"><h3 class="news__title">' + item.name + '</h3><p class="news__description">' + item.description + '</p></a></article>'
            });
            $('#tabs__content--subdivisions .search-results__list').html(content);
            this.all_content += content
        }

    };
}
