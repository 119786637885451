import * as types from "../constants/ActionTypes";
import {createSelector} from "@reduxjs/toolkit";
import {arrayMoveImmutable} from "array-move";

const initialState = {subdivisions: []};

export function subdivisions(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_SUBDIVISION_STARTED:
            return state;

        case types.GET_SUBDIVISION_SUCCESS:
            return action.payload;

        case types.GET_SUBDIVISION_ERROR:
            return state;

        case types.ADD_SUBDIVISION_STARTED:
            return state;

        case types.ADD_SUBDIVISION_SUCCESS:
            return {
                ...state,
                subdivisions: [...state.subdivisions, action.payload],
            };

        case types.ADD_SUBDIVISION_ERROR:
            return state;

        case types.DELETE_SUBDIVISION_STARTED:
            return state;

        case types.DELETE_SUBDIVISION_SUCCESS:
            return {
                ...state,
                subdivisions: state.subdivisions.filter((item) => item.id !== parseInt(action.payload)),
            };

        case types.DELETE_SUBDIVISION_ERROR:
            return state;

        case types.EDIT_SUBDIVISION_STARTED:
            return state;

        case types.EDIT_SUBDIVISION_SUCCESS:
            return {
                ...state,
                subdivisions: state.subdivisions.map((item) => {
                    return item.id === parseInt(action.payload.id) ? action.payload : item;
                }),
            };

        case types.EDIT_SUBDIVISION_ERROR:
            return state;

        case types.DELETE_PARENT_SUBDIVISION_GROUP:
            return {
                ...state,
                subdivisions: state.subdivisions.map((item) => {
                    if (item.subdivisionGroupId !== String(action.payload)) {
                        return item;
                    } else {
                        return {
                            ...item,
                            subdivisionGroupId: null,
                        }
                    }
                })
            };
        case types.DELETE_SUBDIVISION_PERSON:
            return {
                ...state,
                subdivisions: state.subdivisions.map((item) => {
                    if (item.leaderPersonId === parseInt(action.payload)) {
                        return {
                            ...item,
                            leaderPersonId: null,
                            leaderPosition: '',
                        }
                    } else if (item.personIds.includes(action.payload)) {
                        return {
                            ...item,
                            personIds: item.personIds.filter((id) => id !== parseInt(action.payload)),
                        }
                    } else {
                        return item;
                    }
                })
            };
        case types.SORT_SUBDIVISION:
            const subdivisionFromGroup = state.subdivisions.filter((item) => item.subdivisionGroupId === parseInt(action.payload.groupId));
            const otherSubdivisions = state.subdivisions.filter((item) => item.subdivisionGroupId !== parseInt(action.payload.groupId));
            const immutableSubdivisions = arrayMoveImmutable(subdivisionFromGroup, action.payload.oldIndex, action.payload.newIndex);

            return {
                ...state,
                subdivisions: [...immutableSubdivisions, ...otherSubdivisions],
            };
        case types.SORT_SUBDIVISION_WITHOUT_GROUP:
            const newArray = action.payload.reduce((current, id) => {
                const found = state.subdivisions.find((item) => item.id === parseInt(id));
                if (!found) {
                    return current;
                } else {
                    return [...current, found]
                }
            }, []);

            let arr = state.subdivisions;
            action.payload.forEach((id) => {
                arr = arr.filter((item) => item.id !== parseInt(id));
            });

            return {
                ...state,
                subdivisions: [
                    ...arr,
                    ...newArray,
                ]
            };
        default:
            return state;
    }
}

export const selectAllSubdivisions = state => state.subdivisions.subdivisions;

export const selectSubdivisionById = (id) => createSelector(
    selectAllSubdivisions,
    (subdivisions) => subdivisions.find((item) => parseInt(item.id, 10) === parseInt(id, 10)),
);

export const selectSubdivisionWithoutGroup = () => createSelector(
    selectAllSubdivisions,
    (subdivisions) => subdivisions.filter((item) => item.subdivisionGroupId === null),
);

export const selectParentSubdivisions = (id) => createSelector(
    selectAllSubdivisions,
    (subdivisions) => subdivisions.filter((item) => item.id !== parseInt(id) && item.subdivisionGroupId === null),
);

export const selectSubdivisionsBySubdivisionGroupId = (id) => createSelector(
    selectAllSubdivisions,
    (subdivisions) => subdivisions.filter((item) => item.subdivisionGroupId === parseInt(id)),
);

export const selectSubdivisionsBySubdivisionId = (id) => createSelector(
    selectAllSubdivisions,
    (subdivisions) => subdivisions.filter((item) => item.subdivisionId === parseInt(id)),
);
