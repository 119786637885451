import {SiteMenuController as SiteMenuControllerBase} from '@make/ds2';

export default class SiteMenuController extends SiteMenuControllerBase {
    static targets = ['text', 'buttonText', 'searchInput', 'searchButton'];

    toggle() {
        this.element.classList.toggle('disabled');
    }

    connect() {
        if (this.element.dataset.spa) {
            this.isTransitionedWithTurbolinks = true;
        }
        this.submenuTargets.forEach((submenu) => {
            submenu.addEventListener('transitionend', this.restoreHeightAfterTransition);
            submenu.classList.remove('opened');
        });

        if (this.menuTarget) {
            for (let i = 0; i < this.menuTarget.children.length; i++) {
                const item = this.menuTarget.children[i];
                item.addEventListener('mouseenter', this.toggleSubmenu);
                item.addEventListener('mouseleave', this.toggleSubmenu);
            }
        }

        const area = document.createElement('div');
        area.classList.add('extended-hover');

        this.linkTargets.forEach((link) => {
            const element = area.cloneNode();
            link.appendChild(element);
            this.extendedHoverAreas.push(element);
        });
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('bindScroll', this.onBindScroll);
        window.addEventListener('unbindScroll', this.onUnbindScroll);
        document.addEventListener('turbolinks:click', this.onTurbolinksClick);
        document.addEventListener('turbolinks:before-render', this.onTurbolinksBeforeRender);

        let fontHeight = parseInt(window.getComputedStyle(this.linkTargets[0]).fontSize, 10);
        this.textTargets.forEach(element => {
            if (fontHeight && (fontHeight * 2) <= element.clientHeight) {
                this.buttonTextTarget.style.display = "none";
                this.linkTargets[this.linkTargets.length - 1].style.display = "none";
            }
            fontHeight = element.clientHeight;
        });
    }

    searchKeyUp(e) {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            window.location.href = `/search-result?query=${window.$(this.searchInputTarget).val()}`
        }
    }

    searchClick(e) {
        window.location.href = `/search-result?query=${window.$(this.searchInputTarget).val()}`
    }
}
