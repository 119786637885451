import React, {useEffect, useState} from "react";
import {useNode} from "@craftjs/core";

import TextArea from "../../../templates/TextArea/TextArea";
import Button from "../../../templates/Button/Button";
import TextInput from "../../../templates/TextInput/TextInput";

import '../../../../../styles/blocks/constructor_components/form_components/form_in_text.scss'
import {validateFormInit} from "../../../../public_site/jquery-libs/validation";
import CheckBox from "../../../templates/CheckBox/CheckBox";
import FileUploaderPublic from "../../../templates/FileUploader/FileUploaderPublic";
import {randomID} from "../../../../helpers/randomId";
import $ from "jquery";
import {BASE_URL} from "../../../../constants/EnvVariables";
import {snakeKeys} from "js-convert-case";

export const FormInTextPublic = (props) => {
    const [message, setMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [tempFiles, setTempFiles] = useState([]);
    const [filesIds, setFilesIds] = useState(null);
    const [confirmationCode, setConfirmationCode] = useState(null);
    let filesIdsString = null;

    const addDocument = (item) => {
        let data = {
            id: item.id ? item.id : randomID(),
            title: item.name.substring(0, item.name.indexOf('.' + item.extension)),
            description: '',
            ...item
        };
        setTempFiles(tempFiles => [...tempFiles, data])

        if(item.id){
            if(filesIdsString){
                filesIdsString = filesIdsString + ',' + item.id;
                setFilesIds(filesIdsString)
            }
            else {
                filesIdsString = item.id;
                setFilesIds(filesIdsString);
            }
        }
    };

    useEffect(() => {
        if (props.setComponentsListOpen) {
            props.setComponentsListOpen(false);
        }

        if (process.env.REACT_APP_BUILD_MODE !== 'production') {
            validateFormInit();
        }
    }, []);

    const submitForm = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let errorFields = {};

        const inputWrappers = $(event.target).find('.constructor-input-field__wrapper');
        let data = {
            path: window.location.href
        };

        $.each(inputWrappers, function (index, item) {
            const errorMessageWrapper = $(item).find('.constructor-input-field__error-message');
            const input = $(item).find(':input')[0];
            const inputName = $(input).attr('name');
            const value = $(input).val();

            data = {
                ...data,
                [inputName]: value,
            };

            $(item).removeClass('error');
            $(errorMessageWrapper).text('');

            let errorMessage;

            switch (inputName) {
                case 'phone':
                    errorFields.phone = value.trim().length === 0;
                    errorMessage = 'Введите номер телефона';
                    break;
                case 'name':
                    errorFields.name = value.trim().length === 0;
                    errorMessage = 'Введите имя';
                    break;
                case 'text':
                    errorFields.text = value.trim().length === 0;
                    errorMessage = 'Введите текст соощения';
                    break;
                case 'email':
                    errorFields.email = !emailPattern.test(value);
                    errorMessage = 'Введите кореектный E-mail';
                    break;
                case 'code':
                    errorFields.code = value.trim().length === 0;
                    errorMessage = 'Введите код';
                    break;
            }

            if (errorFields[inputName]) {
                $(item).addClass('error');
                $(errorMessageWrapper).text(errorMessage);
            }
        });

        const canSend = !Object.values(errorFields).find((item) => item === true);

        if (canSend) {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: BASE_URL + 'api/feedbacks',
                data: snakeKeys(data),
                success: function (data, status, xhr) {
                    $.each(inputWrappers, function (index, item) {
                        const input = $(item).find(':input')[0];
                        $(input).val('');
                    });
                    setMessage('');
                    setUserName('');
                    setEmail('');
                    setPhone('');
                    setTempFiles([]);
                    setConfirmationCode('');
                    setFilesIds(null);

                    alert('Ваше сообщение успешно отправлено');
                },
                error: function (data) {
                    if(data.responseJSON.message){
                        alert(data.responseJSON.message);
                    }
                    else{
                        alert('Произошла ошибка');
                    }
                }
            })
        }
    };

    return (
        <div className={'constructor-component__wrapper'}>
            <div className={'constructor-component__content'}>
                <form className={'constructor-form-in-text constructor-form-in-text--new'} onSubmit={(event) => submitForm(event)}>
                    <div className='component-row-2-columns'>
                        <TextInput
                            label='ФИО'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            name='name'
                        />

                        <TextInput
                            label='Номер телефона'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            name='phone'
                            className='public-site__phone-input'
                        />
                    </div>
                    <div className=' component-row-2-columns'>
                        <TextInput
                            label='Электронная почта'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name='email'
                        />
                        <div className={'constructor-input-field__wrapper'} style={{display: 'none'}}>
                            <input className={'constructor-form-in-text__submit--site-id-input'} name="site_id" value={null} hidden/>
                        </div>
                    </div>

                    <div className='component-row-2-columns'>
                        <TextInput
                            label='Код подтверждения'
                            description={'Подтвердите электронную почту, чтобы продолжить'}
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                            name='code'
                            class={'feedback-form--code-input'}
                        />

                        <div className={'constructor-input-field__wrapper '}>
                            <div className="constructor-input-field__label-wrapper">
                                <label className="constructor-input-field__label"></label>
                                <div className="constructor-input-field__label-description constructor-form-in-text__submit--cod-confirmation-description">
                                    Запросить код повторно Вы можете через <span className={'constructor-form-in-text--timer'}>20</span> секунд
                                </div>
                                <span className="constructor-input-field__error-message"></span>
                            </div>
                            <Button
                                title='Запросить код'
                                additionalClass='constructor-form-in-text__submit--cod-confirmation'
                                buttonType='button'
                            />
                        </div>

                    </div>

                    <div className='component-row'>
                        <TextArea
                            label='Текст сообщения'
                            value={message}
                            defaultLinesNumber={5}
                            onChange={(e) => setMessage(e.target.value)}
                            name='text'
                        />
                    </div>


                    <div className='component-row'>
                        <div className="constructor-input-field__label-wrapper">
                            <label className="constructor-input-field__label">Прикрепить файл</label>
                            <div className="constructor-input-field__label-description">
                                Файлы в формате txt, doc, docx, rtf, xls, xlsx, ppt, pub, pdf, jpg, bmp, png, tif, gif, mp3, wma, avi, mp4, mkv, wmv, не более 25 мб
                            </div>
                            <span className="constructor-input-field__error-message"></span>
                        </div>

                        <FileUploaderPublic
                            fileName={tempFiles && tempFiles.length > 0 ? 'Количество файлов: ' + tempFiles.length : null}
                            onChange={(file) => {
                                addDocument(file);
                            }}
                            accept={'.txt, .doc, .docx, .rtf, .xls, .xlsx, .ppt, .pub, .pdf, .jpg, .bmp, .png, .tif, .gif, .mp3, .wma, .avi, .mp4, .mkv, .wmv'}
                            // id={tempFile && tempFile.id ? tempFile.id : ''}
                            multiple
                        />

                        <div className={'constructor-input-field__wrapper'} style={{display: 'none'}}>
                            <input name="files_ids" value={filesIds} hidden/>
                        </div>
                    </div>

                    <div className='component-row'>
                        <CheckBox
                            checked={true}
                            additionalClass='constructor-form-in-text__submit--checkbox-confirmation'
                        >
                            Я соглашаюсь с <a className={'constructor-form-in-text__submit--checkbox-confirmation-link'} href={'https://kuzgosweb-adm.kemobl.ru/personal_data.pdf'} target={'_blank'}>Политикой обработки персональных данных</a>
                        </CheckBox>
                    </div>

                    <div className='component-row-2-columns'>
                        <Button
                            title='Отправить сообщение'
                            additionalClass='constructor-form-in-text__submit'
                            buttonType='submit'
                            disabled
                        />
                    </div>

                </form>
            </div>
        </div>

    )
};
