import React from 'react';
import SnackbarAlert from "./SnackbarAlert";
import {Snackbar} from "@material-ui/core";

export const SnackbarBlock = (props) => {

    return (
        <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={!!props.snackbarMessage}
            onClose={() => props.setSnackbarMessage(null)}
        >
            <SnackbarAlert onClose={() => props.setSnackbarMessage(null)} severity={props.severity}
                           sx={{width: '100%'}}>
                {props.snackbarMessage}
            </SnackbarAlert>
        </Snackbar>
    )
};

export default SnackbarBlock;
