import * as types from '../constants/ActionTypes';
import Cookies from "js-cookie";
import {BASE_URL} from "../constants/EnvVariables";
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getPersons() {
    return dispatch => {
        dispatch({
            type: types.GET_PERSON_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/persons",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_PERSON_SUCCESS,
                    payload: data,
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_PERSON_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    };
}

export function addPersonality(person) {
    return dispatch => {
        dispatch({
            type: types.ADD_PERSON_STARTED
        });

        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: BASE_URL + "api/persons",
                data: snakeKeys(person),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    person.id = data.id;
                    dispatch({
                        type: types.ADD_PERSON_SUCCESS,
                        payload: person,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.ADD_PERSON_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    }
}

export function editPersonality(person) {
    return dispatch => {
        dispatch({
            type: types.EDIT_PERSON_STARTED
        });

        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "PUT",
                dataType: 'json',
                url: BASE_URL + "api/persons/" + person.id,
                data: snakeKeys(person),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    dispatch({
                        type: types.EDIT_PERSON_SUCCESS,
                        payload: person,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.EDIT_PERSON_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    }
}

export function deletePersonality(persons, id) {
    return dispatch => {
        if (!window.editBlocked) {
            window.$.ajax({
                type: "DELETE",
                dataType: 'json',
                url: BASE_URL + "api/persons/" + id,
                data: {id: id},
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    dispatch({
                        type: types.DELETE_PERSON_SUCCESS,
                        payload: persons,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.DELETE_PERSON_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }

}

export function setPersonalitiesSection(flag, parentSection) {
    return dispatch => {
        dispatch({
            type: types.SET_PERSONALITIES_SECTION,
            payload: {flag, parentSection},
        })
    }
}

export function deletePersonalitiesParentSection(slug) {
    return dispatch => {
        dispatch({
            type: types.DELETE_PERSONALITIES_PARENT_SECTION,
            payload: slug,
        })
    }
}

export function clearPersons() {
    return dispatch => {
        dispatch({
            type: types.GET_PERSON_SUCCESS,
            payload: [],
        });
    };
}
