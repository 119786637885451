import React, {useEffect, useRef} from "react";

import "./Hint.scss";

const Hint = ({title, show}) => {
    const hintRef = useRef();

    useEffect(() => {
        const onShowTooltip = (event) => {
            const element = hintRef.current;
            if (element) {
                const {width, height} = element.getBoundingClientRect();
                element.style.left = `${event.clientX - width / 2}px`;
                element.style.top = `${event.clientY - height - 3}px`;
            }
        };

        document.addEventListener("mousemove", onShowTooltip);

        return () => {
            document.removeEventListener("mousemove", onShowTooltip);
        };
    }, [show]);

    return (
        <div ref={hintRef} className={`constructor-hint ${show ? "show" : ""}`}>
            {title}
        </div>
    );
};

export default Hint;
