import {Controller} from 'stimulus';

export default class PhotoSliderController extends Controller {
    static targets = ['prev', 'next', 'slider', 'wrapper', 'pagination'];

    connect() {
        const b = document.querySelector('.photo-modal__icon-button').getBoundingClientRect();
        const c = document.querySelector('.photo-modal__wrapper').getBoundingClientRect();
        document.querySelector('.photo-modal__icon-button').style.right = `${b.right - c.right + 24}px`;
    }
}
