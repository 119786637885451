import * as types from "../constants/ActionTypes";
import {createSelector} from "@reduxjs/toolkit";

const initialState = {
    active: false,
    parentSection: null,
    personalities: []
};

export function personalities(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_PERSON_STARTED:
            return state;

        case types.GET_PERSON_SUCCESS:
            return action.payload;

        case types.GET_PERSON_ERROR:
            return state;

        case types.ADD_PERSON_STARTED:
            return state;

        case types.ADD_PERSON_SUCCESS:
            return {
                ...state,
                personalities: [...state.personalities, action.payload]
            };

        case types.ADD_PERSON_ERROR:
            return state;

        case types.EDIT_PERSON_STARTED:
            return state;

        case types.EDIT_PERSON_SUCCESS:
            return {
                ...state,
                personalities: state.personalities.map((personality) => {
                    return personality.id === parseInt(action.payload.id) ? action.payload : personality;
                }),
            };

        case types.EDIT_PERSON_ERROR:
            return state;

        case types.DELETE_PERSON_STARTED:
            return state;

        case types.DELETE_PERSON_SUCCESS:
            return {
                ...state,
                personalities: action.payload
            };

        case types.DELETE_PERSON_ERROR:
            return state;

        case types.SET_PERSONALITIES_SECTION:
            return {
                ...state,
                active: action.payload.flag,
                parentSection: action.payload.parentSection
            };
        case types.DELETE_PERSONALITIES_PARENT_SECTION:
            const canEdit = state.parentSection.slug === action.payload || action.payload === 'public-personalities';
            return {
                ...state,
                active: canEdit ? false : state.active,
                parentSection: canEdit ? null : state.parentSection,
            };
        default:
            return state;
    }
}

export const selectAllPersonalities = state => state.personalities.personalities;
export const selectActivityPersonalities = state => state.personalities.active;
export const selectAPersonalitiesId = state => state.personalities.personalitiesId;
export const selectPersonalitiesParentSection = state => state.personalities.parentSection;

export const selectActivePersonalities = () => createSelector(
    selectAllPersonalities,
    (persons) => persons.filter((person) => person.active),
);

export const selectPersonalities = createSelector(
    selectAllPersonalities,
    (_, searchValue) => searchValue,
    (items, searchValue) => items.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
    })
);

export const selectPersonalityById = (id) => createSelector(
    selectAllPersonalities,
    (items) => items.find((item) => item.id === parseInt(id)),
);

export const selectPersonsByIds = (ids) => createSelector(
    selectAllPersonalities,
    (persons) => {
        return ids.reduce((current, item) => {
            const foundPerson = persons.find((person) => person.id === parseInt(item));
            if (!foundPerson) {
                return current;
            } else {
                return [...current, foundPerson]
            }
        }, [])
    }
);

export const selectPersonsByManagerId = (id) => createSelector(
    selectAllPersonalities,
    (persons) => persons.filter((person) => person.managerId === parseInt(id))
);
