import * as types from '../constants/ActionTypes';

const initialState = '';

export function errorMessage(state, action) {
    state = initialState;

    switch (action.type) {
        case types.GET_STRUCTURE_ERROR:
            return action.message;
        case types.ADD_STRUCTURE_PAGE_ERROR:
            return action.message;
        case types.EDIT_STRUCTURE_PAGE_ERROR:
            return action.message;
        case types.DELETE_STRUCTURE_PAGE_ERROR:
            return action.message;
        case types.GET_PAGES_ERROR:
            return action.message;
        case types.ADD_PAGE_ERROR:
            return action.message;
        case types.EDIT_PAGE_ERROR:
            return action.message;
        case types.GET_SETTINGS_ERROR:
            return action.message;
        case types.SET_SETTINGS_ERROR:
            return action.message;
        case types.GET_NEWS_ERROR:
            return action.message;
        case types.ADD_NEWS_ERROR:
            return action.message;
        case types.EDIT_NEWS_ERROR:
            return action.message;
        case types.DELETE_NEWS_ERROR:
            return action.message;
        case types.GET_ORGANIZATION_CATEGORIES_ERROR:
            return action.message;
        case types.GET_COLOR_SCHEMES_ERROR:
            return action.message;
        case types.GET_SETTINGS_MAIN_PAGE_ERROR:
            return action.message;
        case types.GET_PERSON_ERROR:
            return action.message;
        case types.ADD_PERSON_ERROR:
            return action.message;
        case types.EDIT_PERSON_ERROR:
            return action.message;
        case types.DELETE_PERSON_ERROR:
            return action.message;
        case types.GET_SUBDIVISION_ERROR:
            return action.message;
        case types.ADD_SUBDIVISION_ERROR:
            return action.message;
        case types.EDIT_SUBDIVISION_ERROR:
            return action.message;
        case types.DELETE_SUBDIVISION_ERROR:
            return action.message;
        case types.GET_SUBDIVISION_GROUP_ERROR:
            return action.message;
        case types.ADD_SUBDIVISION_GROUP_ERROR:
            return action.message;
        case types.EDIT_SUBDIVISION_GROUP_ERROR:
            return action.message;
        case types.DELETE_SUBDIVISION_GROUP_ERROR:
            return action.message;
        case types.GET_MANAGEMENT_ERROR:
            return action.message;
        case types.SET_MANAGEMENT_ERROR:
            return action.message;

        default:
            return state;
    }
}

export default errorMessage
