import {AccordionController as AccordionControllerBase} from '@make/ds2';

export default class AccordionController extends AccordionControllerBase {

    description = '';

    renderLinkIcon(node) {
        const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const iconPath = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path'
        );

        iconSvg.setAttribute('viewBox', '0 0 24 24');
        iconSvg.classList.add('icon');

        iconPath.setAttribute(
            'd',
            'M7 10.073L8.099 9 12 12.854 15.902 9 17 10.073 12 15l-5-4.927z'
        );
        iconPath.setAttribute('clip-rule', 'evenodd');

        iconSvg.appendChild(iconPath);

        return node.appendChild(iconSvg);
    }

    toggleDescription(event) {
        if (this.description.length === 0) {
            this.description = event.target.textContent;
        }

        if (event.target.dataset.description) {
            if (this.opened) {
                event.target.textContent = event.target.dataset.description;
            } else {
                event.target.textContent = this.description;
            }

            this.renderLinkIcon(event.target);
        }
    }
}
