import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist'
import thunk from 'redux-thunk';
import localforage from 'localforage';

import reducer from '../reducers'

// Префикс для хранилища
const persistConfig = {
    key: 'ako_site_builder',
    storage: localforage,
};


// регистрируем редьюсеры
const persistedReducer = persistReducer(persistConfig, reducer);

// создаем store по умолчанию
export default function configureStore() {
    const storeRedux = createStore(persistedReducer, composeWithDevTools(
        applyMiddleware(...[thunk]),
    ));
    let persistor = persistStore(storeRedux);
    return {storeRedux, persistor}
}