import * as types from '../constants/ActionTypes';
import {BASE_URL} from "../constants/EnvVariables";
import Cookies from "js-cookie";
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getOrganisationCategories() {
    return dispatch => {
        dispatch({
            type: types.GET_ORGANIZATION_CATEGORIES_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/organisation_categories",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },

            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_ORGANIZATION_CATEGORIES_SUCCESS,
                    info: data,
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_ORGANIZATION_CATEGORIES_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    };
}

export function clearOrganisationCategories() {
    return dispatch => {
        dispatch({
            type: types.GET_ORGANIZATION_CATEGORIES_SUCCESS,
            info: [],
        });
    };
}

export default {getOrganisationCategories}
