import $ from 'jquery';
import {BASE_URL} from "../../constants/EnvVariables";

export function newsCounter() {
    let pathnameArray = window.location.pathname.split('/');
    if (pathnameArray[1] && pathnameArray[2] && pathnameArray[1] === 'news') {
        $.ajax({
            type: "POST",
            dataType: "json",
            url: BASE_URL + 'api/news_articles/' + pathnameArray[2] + '/view?_method=PATCH',
            success: function (data, status, xhr) {
                $('.article__publication-views-count').each(function () {
                    $(this).html(data.viewCount)
                })
            },
            error: function (data) {
            }
        })
    }
}
