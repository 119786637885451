import {Controller} from 'stimulus';

export default class CopyLinkController extends Controller {
    static targets = ['button'];

    copyLink() {
        const temporaryInput = document.body.appendChild(document.createElement('input'));
        temporaryInput.value = window.location.href;
        temporaryInput.focus();
        temporaryInput.select();
        document.execCommand('copy');
        temporaryInput.parentNode.removeChild(temporaryInput);
    }
}
