import React, {useRef} from 'react';
import {useWave} from '../../../hooks/useWave';
import '../../../../styles/public_site/components/button.scss';

const Button = ({
                    title,
                    onClick,
                    type,
                    size,
                    additionalClass,
                    buttonType = 'button',
                    ...otherProps
                }) => {
    const buttonRef = useRef();

    useWave(buttonRef);

    return (
        <button
            ref={buttonRef}
            type={buttonType}
            className={`
      constructor-button
      constructor-button--${type}
      ${size ? `constructor-button--${size}` : ''}
      ${additionalClass ? additionalClass : ''}
    `}
            onClick={onClick}
            {...otherProps}
        >
            {title}
        </button>
    );
};
Button.defaultProps = {
    type: 'primary',
};

export default Button;
