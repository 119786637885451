import * as types from '../constants/ActionTypes';

const initialState = {food: []};

export function food(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_FOOD_STARTED:
            return state;

        case types.GET_FOOD_SUCCESS:
            return action.info;

        case types.GET_FOOD_ERROR:
            return state;


        default:
            return state;
    }
}
