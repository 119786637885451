import * as types from '../constants/ActionTypes';
import {BASE_URL} from '../constants/EnvVariables';
import Cookies from 'js-cookie';
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getNews() {
    return dispatch => {
        dispatch({
            type: types.GET_NEWS_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/news_articles",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_NEWS_SUCCESS,
                    info: {news: data},
                });
            },

            error: function (data) {
                dispatch({
                    type: types.GET_NEWS_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });

                ajaxErrorRedirect(data);

            }
        });

    };
}

export function addNews(info) {

    return dispatch => {
        dispatch({
            type: types.ADD_NEWS_STARTED
        });

        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: BASE_URL + "api/news_articles",
                data: snakeKeys(info),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    info.id = data.id;
                    info.slug = data.slug;
                    info.publicationDate = data.date;
                    dispatch({
                        type: types.ADD_NEWS_SUCCESS,
                        info,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.ADD_NEWS_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    }
}

export function editNews(newsId, info) {
    return dispatch => {
        dispatch({
            type: types.EDIT_NEWS_STARTED
        });

        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "PUT",
                dataType: 'json',
                url: BASE_URL + "api/news_articles/" + newsId + "?_method=PUT",
                data: snakeKeys(info),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    info.id = data.id;
                    info.slug = data.slug;
                    info.publicationDate = data.date;

                    dispatch({
                        type: types.EDIT_NEWS_SUCCESS,
                        newsId,
                        info,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.EDIT_NEWS_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    }
}

export function deleteNews(id) {
    return dispatch => {
        dispatch({
            type: types.DELETE_NEWS_STARTED
        });

        if (!window.editBlocked) {
            window.$.ajax({
                type: "DELETE",
                dataType: 'json',
                url: BASE_URL + "api/news_articles/" + id,
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    dispatch({
                        type: types.DELETE_NEWS_SUCCESS,
                        newsId: id,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.DELETE_NEWS_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            });
        }

    }
}

export function clearNews() {
    return dispatch => {
        dispatch({
            type: types.GET_NEWS_SUCCESS,
            info: {news: []},
        });
    };
}

export default getNews
