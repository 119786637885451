import React, {forwardRef, useState} from 'react';
import {TextareaAutosize} from "@material-ui/core";

import InputField from '../InputField/InputField';
import '../../../../styles/public_site/components/textarea.scss';

const TextArea = forwardRef(({
                                 defaultLinesNumber,
                                 value,
                                 label,
                                 name,
                                 error,
                                 inputClassName = '',
                                 ...otherProps
                             }, ref) => {
    const [minRowsCount, setMinRowsCount] = useState(defaultLinesNumber);
    const [currentHeight, setCurrentHeight] = useState(0);

    const getCurrentHeight = (event) => {
        const {scrollHeight} = event.target;
        setCurrentHeight(scrollHeight)
    };

    const calculateRows = (event) => {
        const element = event.target;
        const style = window.getComputedStyle(element);
        const height = parseFloat(style.height);
        const lineHeight = parseFloat(style.lineHeight);

        if (currentHeight < height) {
            setMinRowsCount(height / lineHeight);
        }
    };

    const onInput = (event) => {
        if (value) {
            let textLength = value.length;
            if (textLength > event.target.textLength) {
                setMinRowsCount(defaultLinesNumber);
            }
        }
    };

    return (
        <InputField label={label} error={error}>
            <div className='textarea__wrapper'>
                <TextareaAutosize
                    ref={ref}
                    className={`constructor-textarea-default ${inputClassName}`}
                    value={value}
                    onInput={onInput}
                    onMouseDown={getCurrentHeight}
                    onMouseUp={calculateRows}
                    name={name}
                    minRows={minRowsCount}
                    {...otherProps}
                />
            </div>
        </InputField>
    );
});

TextArea.defaultProps = {
    defaultLinesNumber: 3,
};

export default TextArea;
