import React from 'react';
import icons from '../../../images/icons.svg';

const Icon = ({name, ...otherProps}) => {
    return (
        <svg
            role="img"
            {...otherProps}
        >
            <use xlinkHref={`${icons}#${name}`}/>
        </svg>
    )
};

export default Icon;
