import {Controller} from 'stimulus';

export default class SiteMenuModalController extends Controller {
    static targets = ['window'];

    recalculateHandler = () => window.requestAnimationFrame(this.recalculate);

    connect() {
        window.addEventListener('resize', this.recalculateHandler);
    }

    disconnect() {
        window.removeEventListener('resize', this.recalculateHandler);
    }

    recalculate = () => {
        let self = this;
        setTimeout(function () {
            const height = window.innerHeight - self.element.getBoundingClientRect().bottom;
            self.windowTargets.forEach((win) => {
                win.style.height = `${height}px`;
            });
        }, 100);
    };
}
