import * as types from '../constants/ActionTypes';
import {BASE_URL} from '../constants/EnvVariables';
import Cookies from 'js-cookie';
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getFeedbacks() {
    return dispatch => {
        dispatch({
            type: types.GET_FEEDBACKS_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/feedbacks",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_FEEDBACKS_SUCCESS,
                    info: {feedbacks: data},
                });
            },

            error: function (data) {
                dispatch({
                    type: types.GET_FEEDBACKS_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });

                ajaxErrorRedirect(data);

            }
        });

    };
}

export function clearFeedbacks() {
    return dispatch => {
        dispatch({
            type: types.GET_FEEDBACKS_SUCCESS,
            info: {feedbacks: []},
        });
    };
}

export default getFeedbacks
