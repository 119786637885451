import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import Button from '../Button/Button';
import Icon from '../Icon';
import './FileUploader.scss';

import {BASE_URL, PUBLIC_URL} from "../../../constants/EnvVariables";
import Cookies from "js-cookie";
import {ajaxErrorRedirect} from "../../../helpers/ajaxErrorRedirect";
import SnackbarBlock from "../../blocks/SnackbarBlock";
import ProgressBar from "@ramonak/react-progress-bar";

const FileUploaderPublic = ({multiple, preview, fileName, onChange, accept, id, directory, sort, error}, ref) => {
    const fileRef = useRef();
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [fileNameCurrent, setFileNameCurrent] = useState();
    const [percentComplete, setPercentComplete] = useState(0);
    const onSelectFile = () => fileRef.current.click();

    useEffect(() => {
        setFileNameCurrent(fileName);
        if (!fileName) {
            setPercentComplete(0);
        }
    }, [fileName]);


    const onFileSelected = () => {
        const files = Array.from(fileRef.current.files);
        sort = sort ?? 0;

        files.forEach((file, index) => {
            let file_size = file.size / 1024 / 1024;

            if (file_size <= 25) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let fileObject = {
                        file: reader.result,
                        name: file.name.substring(0, 256),
                        extension: file.name.match(/\.([a-zA-Z0-9]+)$/)[1],
                        size: file_size < 1 ? `${(file_size * 1024).toFixed(2)} КБ` : `${(file_size).toFixed(2)} МБ`,
                        pageId: null,
                        removeId: id,
                        directory: directory ? directory.match(/(\d+)/)[0] : null,
                        sort: sort + index
                    };

                    if (!window.editBlocked) {
                        window.$.ajax({
                            type: "POST",
                            dataType: 'json',
                            url: BASE_URL + "api/upload",
                            data: fileObject,
                            headers: {
                                'Authorization': "Bearer " + Cookies.get('site_builder_token')
                            },
                            success: function (data, status, xhr) {
                                fileObject.id = data.id;
                                fileObject.file = data.file;
                                fileObject.fullPath = data.fullPath;
                                onChange(fileObject);
                            },

                            error: function (data) {
                                setSnackbarMessage(data.responseJSON.message);
                                // ajaxErrorRedirect(data);
                            },
                            xhr: function () {
                                let xhr = new window.XMLHttpRequest();
                                setPercentComplete(0);
                                xhr.upload.addEventListener("progress", function (evt) {
                                    setPercentComplete((evt.loaded / evt.total) * 100);
                                }, false);
                                return xhr;
                            },
                        });
                    }

                };
            } else {
                setSnackbarMessage('Размер файла превышает 25МБ');
            }
        });
    };

    const deleteFile = () => {
        const confirmDelete = window.confirm('Вы уверены, что хотите удалить файл?');
        if (!confirmDelete) return;

        if (!window.editBlocked) {
            window.$.ajax({
                type: "DELETE",
                dataType: 'json',
                url: BASE_URL + `api/upload/${id}`,
                // data: {id: id},
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },

                success: function (data, status, xhr) {
                    onChange(null);
                },

                error: function (data) {
                    if (data.status == 404) {
                        onChange(null);
                    } else {
                        setSnackbarMessage(data.responseJSON.message);

                        ajaxErrorRedirect(data);
                    }

                }
            });
        }

    };

    return (
        <>
            {error && (
                <span className='constructor-input-field__error-message'>
                        {error.message}
                    </span>
            )}
            <div className={`constructor-file-uploader__wrapper ${preview && preview.file ? 'with-file' : ''}`}>
                {preview && preview.file && (
                    <div
                        className='constructor-file-uploader__preview'
                        style={{
                            backgroundImage: `url("${preview.id ? PUBLIC_URL : ''}${preview.file}")`,
                        }}
                    >
                        <button
                            onClick={deleteFile}
                            className='constructor-file-uploader__delete-button'
                        >
                            <Icon name='delete' className='constructor-file-uploader__delete-icon'/>
                        </button>
                    </div>
                )}
                <div>
                    <div className={`constructor-file-uploader ${error ? 'error' : ''}`}>
                        <Button title='Выбрать файл' type='secondary' onClick={onSelectFile}
                                additionalClass='admin-button'/>
                        {fileNameCurrent ? (
                            <span className='constructor-file-uploader__name constructor-file-uploader__name--selected'>
                            {fileNameCurrent}
                        </span>
                        ) : (
                            <span className='constructor-file-uploader__name'>Файл не выбран.</span>
                        )}
                        <input
                            ref={fileRef}
                            type='file'
                            multiple={multiple}
                            className='constructor-file-uploader__file'
                            onChange={onFileSelected}
                            accept={accept}
                        />

                    </div>

                </div>

                <SnackbarBlock snackbarMessage={snackbarMessage} setSnackbarMessage={setSnackbarMessage}
                               severity={'success'}/>
            </div>

            <div className={`constructor-file-progressbar__block ${percentComplete > 0 ? 'active' : ''}`}>
                <ProgressBar completed={percentComplete} bgColor={'#0061d9'}
                             className="constructor-file-progressbar__wrapper"/>
            </div>
        </>
    );
};

FileUploaderPublic.defaultProps = {
    multiple: false,
    fileName: '',
    preview: '',
    accept: ".jpg, .svg, .png, .jpeg, .gif, .bmp, .jfif, .webp, .tif, .tiff|image/*"
};

export default forwardRef(FileUploaderPublic);
