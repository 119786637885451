import * as types from '../constants/ActionTypes';
import {GET_FEEDBACKS_STARTED} from "../constants/ActionTypes";

const initialState = {feedbacks: []};

export function feedbacks(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_FEEDBACKS_STARTED:
            return state;

        case types.GET_FEEDBACKS_SUCCESS:
            return action.info;

        case types.GET_FEEDBACKS_ERROR:
            return state;


        default:
            return state;
    }
}
