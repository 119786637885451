import $ from 'jquery';
import {BASE_URL, PUBLIC_URL} from "../../constants/EnvVariables";

export function getGeneralBanners() {
    if (window.location.pathname === '/' || window.location.pathname === '/admin/constructor') {
        $.ajax({
            type: "GET",
            dataType: "json",
            url: BASE_URL + 'api/general_banners',
            success: function (data, status, xhr) {
                data.forEach(function (banner, index) {
                    let bannerSlide = '';
                    if (banner.general_banner_type.slug === 'large_with_image_top' || banner.general_banner_type.slug === 'large_with_image_bottom') {
                        bannerSlide = '<div\n' +
                            '  class="banners__slide-wrapper swiper-slide"\n' +
                            '  data-article-slider-target="slide"\n' +
                            '  data-article-slider-img=' + PUBLIC_URL + banner.image.file + '\n' +
                            '  data-article-description=""\n' +
                            '>\n' +
                            '  <a class=\'banners__slide\' target=\'_blank\' href=' + banner.link + '>\n' +
                            '    <img\n' +
                            '       class=\'banner__image\'\n' +
                            '       data-src=' + PUBLIC_URL + banner.image.file + '\n' +
                            '       data-src-webp=' + PUBLIC_URL + banner.image.file + '\n' +
                            '       src=' + PUBLIC_URL + banner.image.file + '\n' +
                            '       alt="banner__image"\n' +
                            '    />\n' +
                            '  </a>\n' +
                            '</div>';
                    }

                    if (banner.general_banner_type.slug === 'large_with_button_top' || banner.general_banner_type.slug === 'large_with_button_bottom') {
                        bannerSlide = '<div\n' +
                            '  class="banners__slide-wrapper swiper-slide"\n' +
                            '  data-article-slider-target="slide"\n' +
                            '  data-article-slider-img=' + PUBLIC_URL + banner.image.file + '\n' +
                            '  data-article-description=""\n' +
                            '>\n' +
                            '<div class=\'banners__slide banners__slide-with-text\'>\n' +
                            '<div class=\'banners__slide-image-wrapper\'>\n' +
                            '<img\n' +
                            'class=\'banner__image\'\n' +
                            'data-src=' + PUBLIC_URL + banner.image.file + '\n' +
                            'data-src-webp=' + PUBLIC_URL + banner.image.file + '\n' +
                            'src=' + PUBLIC_URL + banner.image.file + '\n' +
                            'alt="banner__image"\n' +
                            '/>\n' +
                            '</div>\n' +
                            '<a class=\'banners__slide-content\' target=\'_blank\' href=' + banner.link + '>\n' +
                            '<div class=\'banners__slide-content-wrapper\'>\n' +
                            '<div class=\'banners__slide-content-text-block\'>\n' +
                            '<div class=\'banners__slide-content-title\'>\n' +
                            banner.title +
                            '</div>\n' +
                            '<div class=\'banners__slide-content-text\'>\n' +
                            banner.text +
                            '</div>\n' +
                            '</div>\n' +
                            '<div class=\'banners__slide-content-button-block\'>\n' +
                            '<button class=\'constructor-button constructor-button--primary banners__slide-content-button\'>\n' +
                            banner.button_text +
                            '</button>\n' +
                            '</div>\n' +
                            '</div>\n' +
                            '</a>\n' +
                            '</div>\n' +
                            '</div>';
                    }


                    if (banner.general_banner_type && banner.general_banner_type.slug === 'info') {
                        $('.public-banner__message-block').addClass('active');
                        $('.public-banner__message-block-text').html(banner.text);
                    } else if (banner.general_banner_type && banner.general_banner_type.slug === 'large_with_image_top') {
                        $('.public-banner__top-block-slider .swiper-wrapper').append($(bannerSlide));
                        $('.public-banner__top-block-slider').addClass('active');
                    } else if (banner.general_banner_type && banner.general_banner_type.slug === 'large_with_button_top') {
                        $('.public-banner__top-block-slider .swiper-wrapper').append($(bannerSlide));
                        $('.public-banner__top-block-slider').addClass('active');
                    } else if (banner.general_banner_type && banner.general_banner_type.slug === 'large_with_image_bottom') {
                        $('.public-banner__bottom-block-slider .swiper-wrapper').append($(bannerSlide));
                        $('.public-banner__bottom-block-slider').addClass('active');
                    } else if (banner.general_banner_type && banner.general_banner_type.slug === 'large_with_button_bottom') {
                        $('.public-banner__bottom-block-slider .swiper-wrapper').append($(bannerSlide));
                        $('.public-banner__bottom-block-slider').addClass('active');
                    } else if (banner.general_banner_type && banner.general_banner_type.slug === 'script_top') {
                        $('.public-banner__top-block-scripts').append(banner.html_code);
                        $('.public-banner__top-block-scripts').addClass('active');
                    } else if (banner.general_banner_type && banner.general_banner_type.slug === 'script_bottom') {
                        $('.public-banner__bottom-block-scripts').append(banner.html_code);
                        $('.public-banner__bottom-block-scripts').addClass('active');
                    }
                });

                if ($('.public-banner__top-block-slider .banners__slide-wrapper').length > 1) {
                    $('.public-banner__top-block-slider .constructor-slider__button').addClass('active')
                }

                if ($('.public-banner__bottom-block-slider .banners__slide-wrapper').length > 1) {
                    $('.public-banner__bottom-block-slider .constructor-slider__button').addClass('active')
                }

                setTimeout(function () {
                    window.reloadGallerySlider();
                }, 1000);
            },
            error: function (data) {
            }
        });

        $('.public-banner__message-block-close').on('click', function () {
            $('.public-banner__message-block').removeClass('active');
        })

    }
}
