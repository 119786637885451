import $ from 'jquery';
import {BASE_URL} from "../../constants/EnvVariables";
import 'jquery-mask-plugin/dist/jquery.mask.min';
import {snakeKeys} from 'js-convert-case';

export function validateFormInit() {
    $('.public-site__phone-input').mask('+7 (999) 999-99-99', {placeholder: "+7 (___) ___-__-__"});

    let formCheckboxChecked = true;

    $.each($('.constructor-form-in-text:not(.init)'), function (index, item) {
        $(item).addClass('init');

        let codeInput = $(this).find('.feedback-form--code-input');
        let formRenderBlock = $(this).parents('#constructor-form-in-text--render-block')[0];
        let checkboxConfirmationLink = $(this).find('.constructor-form-in-text__submit--checkbox-confirmation-link')[0];
        let siteIdInput = $(this).find('.constructor-form-in-text__submit--site-id-input')[0];

        $(checkboxConfirmationLink).attr('href', $(formRenderBlock).attr('data-personal-data-doc'))
        $(siteIdInput).val($(formRenderBlock).attr('data-site-id'))

        $(codeInput).on('change', function () {
            const buttonSubmit = $(item).find('.constructor-form-in-text__submit');
            $(buttonSubmit).attr('disabled', null);
        })



        if(!$(item).hasClass('constructor-form-in-text--new')){
            $(item).on('submit', function (event) {
                event.stopPropagation();
                event.preventDefault();

                const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                let errorFields = {};

                const inputWrappers = $(this).find('.constructor-input-field__wrapper');
                let data = {
                    path: window.location.href
                };

                $.each(inputWrappers, function (index, item) {
                    const errorMessageWrapper = $(item).find('.constructor-input-field__error-message');
                    const input = $(item).find(':input')[0];
                    const inputName = $(input).attr('name');
                    const value = $(input).val();


                    data = {
                        ...data,
                        [inputName]: value,
                    };

                    $(item).removeClass('error');
                    $(errorMessageWrapper).text('');

                    let errorMessage;

                    switch (inputName) {
                        case 'phone':
                            errorFields.phone = value.trim().length === 0;
                            errorMessage = 'Введите номер телефона';
                            break;
                        case 'name':
                            errorFields.name = value.trim().length === 0;
                            errorMessage = 'Введите имя';
                            break;
                        case 'text':
                            errorFields.text = value.trim().length === 0;
                            errorMessage = 'Введите текст соощения';
                            break;
                        case 'email':
                            errorFields.email = !emailPattern.test(value);
                            errorMessage = 'Введите кореектный E-mail';
                            break;
                        case 'code':
                            errorFields.code = value.trim().length === 0;
                            errorMessage = 'Введите код';
                            break;
                    }

                    if (errorFields[inputName]) {
                        $(item).addClass('error');
                        $(errorMessageWrapper).text(errorMessage);
                    }
                });

                const canSend = !Object.values(errorFields).find((item) => item === true);

                if (canSend) {
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: BASE_URL + 'api/feedbacks',
                        data: snakeKeys(data),
                        success: function (data, status, xhr) {
                            $.each(inputWrappers, function (index, item) {
                                const input = $(item).find(':input')[0];
                                $(input).val('');
                            });
                            alert('Ваше сообщение успешно отправлено');
                        },
                        error: function (data) {
                            if(data.responseJSON.message){
                                alert(data.responseJSON.message);
                            }
                            else{
                                alert('Произошла ошибка');
                            }
                        }
                    })
                }
            });
        }


    });


    $.each($('.constructor-form-in-text__submit--cod-confirmation:not(.init)'), function (index, item) {
        $(item).addClass('init');

        $(item).on('click', function (event) {
            event.stopPropagation();
            event.preventDefault();

            const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            let errorFields = {};

            const inputWrappers = $($(this).parents('.constructor-form-in-text')[0]).find('.constructor-input-field__wrapper');
            const buttonSubmit = $($(this).parents('.constructor-form-in-text')[0]).find('.constructor-form-in-text__submit');
            let email = null;

            $.each(inputWrappers, function (index, item) {
                const errorMessageWrapper = $(item).find('.constructor-input-field__error-message');
                const input = $(item).find(':input')[0];
                const inputName = $(input).attr('name');
                const value = $(input).val();

                $(item).removeClass('error');
                $(errorMessageWrapper).text('');

                let errorMessage;

                switch (inputName) {
                    case 'email':
                        errorFields.email = !emailPattern.test(value);
                        errorMessage = 'Введите кореектный E-mail';
                        email = value;
                        break;
                }

                if (errorFields[inputName]) {
                    $(item).addClass('error');
                    $(errorMessageWrapper).text(errorMessage);
                }
            });

            const canSend = !Object.values(errorFields).find((item) => item === true);

            if (canSend && email) {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: BASE_URL + 'api/feedbacks/set_confirmation_code',
                    data: {email: email},
                    success: function (data, status, xhr) {
                        $('.constructor-form-in-text__submit--cod-confirmation').attr('disabled', 'disabled');
                        $('.constructor-form-in-text__submit--cod-confirmation-description').addClass('active');


                        let timerSecond = 20;
                        let timerId = setInterval(function (){
                            $('.constructor-form-in-text--timer').html(timerSecond);
                            timerSecond = timerSecond - 1;

                            if(timerSecond < 0){
                                clearInterval(timerId);
                                $('.constructor-form-in-text__submit--cod-confirmation').attr('disabled', null);
                                $('.constructor-form-in-text__submit--cod-confirmation-description').removeClass('active');
                                $('.constructor-form-in-text--timer').html('20');
                            }
                        }, 1000)
                    },
                    error: function (data) {
                        alert('Произошла ошибка');
                    }
                })
            }


        });
    });



    $.each($('.constructor-form-in-text__submit--checkbox-confirmation:not(.init)'), function (index, item) {
        $(item).addClass('init');

        $(item).on('click', function (event) {
            event.stopPropagation();
            event.preventDefault();

            $(item).toggleClass('checked');
            formCheckboxChecked = !formCheckboxChecked;

            const buttonSubmit = $($(this).parents('.constructor-form-in-text')[0]).find('.constructor-form-in-text__submit');
            const buttonCodeSubmit = $($(this).parents('.constructor-form-in-text')[0]).find('.constructor-form-in-text__submit--cod-confirmation');
            $(buttonSubmit).attr('disabled', formCheckboxChecked ? null : 'disabled');
            $(buttonCodeSubmit).attr('disabled', formCheckboxChecked ? null : 'disabled');
        });
    });


}
