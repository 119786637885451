export default class DisclaimerPopup {
    constructor() {
        this.element = document.querySelector('.disclaimer') || document.createElement('div');
        this.init();
    }


    showOnce() {
        if (this.element) {
            this.element.classList.add('disclaimer--visible');
            this.element.classList.remove('disclaimer--disabled');
        }
    }


    hide() {
        if (this.element) {
            this.element.classList.remove('disclaimer--visible');
            this.element.classList.add('disclaimer--disabled');
        }
    }


    close() {
        this.hide();
        window.localStorage.setItem('gosweb-disclaimer-shown', true);
    }


    init() {
        // Don't show the popup again:
        if (window.localStorage.getItem('gosweb-disclaimer-shown')) {
            this.element.classList.add('disclaimer--disabled');
        }

        this.closeLink = this.element.querySelector('.disclaimer__close');

        if (this.closeLink) {
            this.closeHandler = this.close.bind(this);
            this.closeLink.addEventListener('click', this.closeHandler);
            this.closeLink.addEventListener('touchend', this.closeHandler);
        }
    }

    unload() {
        if (this.closeLink) {
            this.closeLink.removeEventListener('click', this.closeHandler);
            this.closeLink.removeEventListener('touchend', this.closeHandler);
        }
    }
}
