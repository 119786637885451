import React from 'react';
import Icon from '../Icon';

import '../../../../styles/public_site/components/checkbox.scss';

const CheckBox = ({checked, onClick, children, bordered, additionalClass = ''}) => {
    const onKeyDown = (event) => {
        if (event.code === 'Space') {
            onClick();
        }
    };

    const label = children ? (
        <span
            tabIndex='-1'
            onKeyDown={onKeyDown}
            className='constructor-checkbox__label'
            onClick={onClick}
        >
      {children}
    </span>
    ) : null;

    return (
        <div className='constructor-checkbox__wrapper'>
            <button
                onClick={onClick}
                className={`constructor-checkbox ${bordered ? 'bordered' : ''} ${checked ? 'checked' : ''} ${additionalClass}`}
                type={'button'}
            >
                <Icon name='check' className='constructor-checkbox__icon'/>
            </button>
            {label}
        </div>
    );
};

export default CheckBox;
