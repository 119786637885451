import * as types from '../constants/ActionTypes';
import {createSelector} from "@reduxjs/toolkit";
import {arrayMoveImmutable} from "array-move";

const initialState = {
    groups: [
        {id: '1', name: 'Учебный отдел', slug: 'slug1', subdivisionIds: ["1"]},
        {id: '2', name: 'Учебный отдел 2', slug: 'slug2', subdivisionIds: ["3"]},
        {id: '3', name: 'Учебный отдел 3', slug: 'slug3', subdivisionIds: ["2", "4"]},
    ]
};

export function subdivisionGroups(state, action) {
    state = state || initialState;

    switch (action.type) {
        case types.GET_SUBDIVISION_GROUP_STARTED:
            return state;

        case types.GET_SUBDIVISION_GROUP_SUCCESS:
            return action.payload;

        case types.GET_SUBDIVISION_GROUP_ERROR:
            return state;

        case types.ADD_SUBDIVISION_GROUP_STARTED:
            return state;

        case types.ADD_SUBDIVISION_GROUP_SUCCESS:
            return {
                ...state,
                groups: [...state.groups, action.payload],
            };

        case types.ADD_SUBDIVISION_GROUP_ERROR:
            return state;

        case types.DELETE_SUBDIVISION_GROUP_SUCCESS:
            return {
                ...state,
                groups: [...state.groups.filter((group) => group.id !== parseInt(action.payload))],
            };
        case types.SORT_SUBDIVISION_GROUPS:
            return {
                ...state,
                groups: arrayMoveImmutable([...state.groups], action.payload.oldIndex, action.payload.newIndex),
            };
        case types.EDIT_SUBDIVISION_GROUP_STARTED:
            return state;
        case types.EDIT_SUBDIVISION_GROUP_SUCCESS:
            return {
                ...state,
                groups: state.groups.map((group) => {
                    return group.id === parseInt(action.payload.id) ? action.payload : group;
                })
            };
        case types.EDIT_SUBDIVISION_GROUP_ERROR:
            return state;
        default:
            return state;
    }
}

export const selectAllGroups = state => state.subdivisionGroups ? state.subdivisionGroups.groups : '';

export const selectSubdivisionGroupById = (id) => createSelector(
    selectAllGroups,
    (groups) => groups.find((group) => parseInt(group.id) === parseInt(id)),
);
